export const Ledger = Object.freeze({
  credit: "CREDIT",
  MIS: "MIS",
  transactionPeriodOptions: [
    { label: "Today", value: "Today" },
    { label: "Last 1 month", value: "1" },
    { label: "Last 3 months", value: "3" },
    { label: "Last 6 months", value: "6" },
    { label: "", value: "" },
  ],
  internalTransferPrefix: "int_off",
  invoiceAndSellerPayouts: "Invoice & Seller Payouts",
  creditNotesAndSales: "Credit note and sales return",
  incomingPayment: "Incoming Payment",
});
