import { pascalCase, validateNull } from "utils";
import { Ledger } from "../constants";

const { internalTransferPrefix } = { ...Ledger };
export const getCustomerName = (notes, transactionReference, type = false) => {
  return transactionReference?.startsWith(internalTransferPrefix)
    ? pascalCase(
        validateNull(type ? notes?.toCompanyName : notes?.fromCompanyName),
      )
    : pascalCase(validateNull(notes?.buyerCompanyName));
};

export const getSellerName = (notes, transactionReference) => {
  return transactionReference?.startsWith(internalTransferPrefix)
    ? pascalCase(validateNull(notes?.toCompanyName))
    : pascalCase(validateNull(notes?.sellerName));
};
