import { Typography, useTheme } from "@mui/material";

import LedgerTable from "../components/LedgerTable";
import InvoiceDownload from "../components/InvoiceDownload";
import { HyperLink, Timestamp } from "components";

import { appendRupeeIcon, formatDate, validateNull } from "utils";
import { Ledger } from "../constants";
import { getCustomerName, getSellerName } from "../utils";

const OrderTab = () => {
  const theme = useTheme();

  const tableHeaderData = [
    {
      title: "Posting date",
      field: "whenCreated",
      render: (ele) => (
        <Timestamp
          timeStamp={ele?.notes?.originalWhenUpdated ?? ele?.whenCreated}
        />
      ),
    },
    {
      title: "Document Date",
      field: "notes.invoiceDate",
      render: (ele) => (
        <Typography fontSize={14}>
          {validateNull(formatDate(ele?.notes?.invoiceDate))}
        </Typography>
      ),
    },
    {
      title: "Customer name",
      field: "notes.buyerCompanyName",
      render: (ele) => (
        <Typography fontSize={14}>
          {getCustomerName(ele?.notes, ele?.transactionReference)}
        </Typography>
      ),
    },
    {
      title: "Seller name",
      field: "notes.sellerName",
      render: (ele) => (
        <Typography fontSize={14}>
          {getSellerName(ele?.notes, ele?.transactionReference)}
        </Typography>
      ),
    },
    {
      title: "Transaction id",
      field: "transactionReference",
      render: (ele) => (
        <Typography fontSize={14}>
          {validateNull(ele?.transactionReference)}
        </Typography>
      ),
    },
    {
      title: "Order no",
      field: "notes.customerOrderNumber",
      render: (ele) => (
        <Typography fontSize={14}>
          <HyperLink title={ele?.notes?.customerOrderNumber} />
        </Typography>
      ),
    },
    {
      title: "Invoice Number",
      field: "notes.invoiceNumber",
      render: (ele) => <InvoiceDownload ele={ele} />,
    },
    {
      title: "Description",
      field: "notes.description",
      render: (ele) => (
        <Typography fontSize={14}>
          {validateNull(ele?.notes?.description)}
        </Typography>
      ),
    },
    // Needed for export
    {
      title: "",
      field: "type",
      render: () => null,
    },
    {
      title: "Amount",
      field: "amount",
      render: (rowData) => {
        const isCredit = rowData.type === Ledger.credit;
        return (
          <Typography
            fontSize={14}
            style={{
              color: isCredit
                ? theme.palette.success.dark
                : theme.palette.error.dark,
            }}
          >
            {appendRupeeIcon(`${rowData?.money?.displayAmount ?? "0"}`)}
            {`(${isCredit ? "Cr." : "Dr."})`}
          </Typography>
        );
      },
    },
    {
      title: "Balance",
      field: "balance",
      render: (ele) => (
        <Typography>
          {appendRupeeIcon(ele?.balanceAmount?.displayAmount)}
        </Typography>
      ),
    },
  ];

  return (
    <LedgerTable
      tableHeaderData={tableHeaderData}
      title="Orders list"
      exportFileName="OrdersList"
      apiName="ledger"
      apiUrl="/oms/payment/ledger?category=ORDER"
    />
  );
};
export default OrderTab;
