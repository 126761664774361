import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { hookFormLabel } from "./constants";

import {
  CustomTextField,
  CustomViewOnly,
  GridSkeleton,
  ErrorMsg,
} from "./ShipmentCustomComponents";
import { ButtonV1, FloatingBar, DropdownV1 } from "components";
import theme from "themeProvider";

import {
  amountWithGST,
  calculateAllFreightCharges,
} from "./shipmentCalculationUtil";
import { getFixedValue, moneyFormat, removeCurrencyFormat } from "utils";
import { GST_OPTIONS, FrieghtChargeWarning, freightCharge } from "./constants";

const OtherServiceCharges = forwardRef(
  (
    { serviceCharges, submitCharges, sellerOrderId, shipmentId, onCloseModal },
    ref,
  ) => {
    const {
      getValues,
      setValue,
      control,
      resetField,
      formState: { errors },
    } = useFormContext();

    const [frieghtChargeWarning, setFreightChargeWarning] = useState(null);
    const [totalFrieghtAmount, setTotalFreightAmount] = useState(0);

    const sellerShipmentsInfo = useSelector((state) => state?.shipmentDetails);
    const orderDetails = useSelector((state) => state?.orderDetails);

    useImperativeHandle(ref, () => ({
      resetValues() {
        Object.keys(serviceCharges).forEach((ele) => {
          resetField(ele);
          resetField(`gst${ele}`);
          resetField(`confirm${ele}`);
          resetField(`tax${ele}`);
        });
        resetField(hookFormLabel.totalInvoiceOtherServicesAmt);
        resetField(hookFormLabel.totalGSTInvoiceAmount);
        resetField(hookFormLabel.totalBaseInvoiceAmt);
        resetField(hookFormLabel.totalInvoiceAmt);
      },
    }));

    const calculateAmountWithGST = (charge, gst, ele) => {
      const result = amountWithGST(charge, gst);
      if (ele === freightCharge) {
        const freightCharge = removeCurrencyFormat(
          orderDetails?.formattedAmounts?.["Freight charges"],
        );
        if (
          freightCharge !== 0 &&
          totalFrieghtAmount + Number(result) > freightCharge
        ) {
          setFreightChargeWarning(FrieghtChargeWarning);
        } else {
          setFreightChargeWarning(null);
        }
      }

      setValue(`tax${ele}`, result);
    };

    const checkError = () => {
      const {
        confirmprocessingCharge,
        confirmloadingCharge,
        confirmfreightCharge,
        confirmotherCharge,
      } = errors;
      if (
        confirmprocessingCharge ||
        confirmloadingCharge ||
        confirmfreightCharge ||
        confirmotherCharge
      ) {
        return true;
      }
      return false;
    };

    useEffect(() => {
      const totalAmount = calculateAllFreightCharges(
        sellerShipmentsInfo,
        sellerOrderId,
        shipmentId,
      );
      setTotalFreightAmount(totalAmount ?? 0);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            position: "sticky",
            top: 0,
            height: 64,
            width: "100%",
            backgroundColor: theme.palette.common.white,
            zIndex: 1,
            justifyContent: "space-between",
          }}
          mb={4}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              color={theme.palette.secondary.main}
              pr={2}
            >
              Invoiced amount
            </Typography>
            <Typography component={"span"}>(other services)</Typography>
          </Box>
          <IconButton
            onClick={onCloseModal}
            size="small"
            style={{
              height: 40,
              marginBottom: 12,
            }}
          >
            <CloseOutlined style={{ height: 24 }} />
          </IconButton>
        </Box>
        {serviceCharges &&
          Object.keys(serviceCharges).map((ele, ind) => {
            return (
              <Box
                style={{
                  borderRadius: 4,
                  border: `1px solid ${theme.palette.grey[400]}`,
                }}
                key={ind}
                p={4}
                mb={4}
              >
                <Typography mb={4}>
                  {serviceCharges[ele]?.displayName}
                </Typography>
                <GridSkeleton
                  firstChildMd={6}
                  secondChildMd={2}
                  firstChild={
                    <Box style={{ display: "flex", flexDirection: "column" }}>
                      <Box
                        style={{ display: "flex", alignItems: "flex-end" }}
                        mb={1}
                      >
                        <Controller
                          control={control}
                          name={ele}
                          defaultValue={serviceCharges[ele][ele]}
                          render={({ field: { value, onChange, onBlur } }) => (
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography mb={2}>Amount</Typography>
                              <CustomTextField
                                value={value}
                                mask={true}
                                onChange={onChange}
                                customPadding={2}
                                onBlur={onBlur}
                              />
                            </Box>
                          )}
                        />
                        <Controller
                          control={control}
                          name={`confirm${ele}`}
                          defaultValue={serviceCharges[ele][ele]}
                          render={({ field: { value, onChange, onBlur } }) => (
                            <CustomTextField
                              value={value}
                              onChange={(evt) => {
                                onChange(evt.target.value);
                                calculateAmountWithGST(
                                  evt?.target.value,
                                  getValues(`gst${ele}`),
                                  ele,
                                );
                              }}
                              highlightError={errors?.[
                                `confirm${ele}`
                              ]?.hasOwnProperty("message")}
                              customPadding={2}
                              onBlur={onBlur}
                            />
                          )}
                        />
                      </Box>
                      <ErrorMsg msg={errors?.[`confirm${ele}`]?.["message"]} />
                      {!!(ele === freightCharge) && (
                        <ErrorMsg msg={frieghtChargeWarning} />
                      )}
                    </Box>
                  }
                  secondChild={
                    <Box marginRight={3}>
                      <Typography fontWeight={600} style={{ marginBottom: 8 }}>
                        GST %
                      </Typography>
                      <Controller
                        control={control}
                        name={`gst${ele}`}
                        defaultValue={serviceCharges[ele]?.gst}
                        render={({ field: { value, onChange } }) => (
                          <DropdownV1
                            itemList={GST_OPTIONS}
                            valueKey="value"
                            labelKey="label"
                            value={value.toString()}
                            onChange={(evt) => {
                              calculateAmountWithGST(
                                getValues(`confirm${ele}`),
                                evt.target.value,
                                ele,
                              );
                              onChange(evt);
                            }}
                            menuStyle={{
                              minWidth: 80,
                              padding: "6px",
                            }}
                          />
                        )}
                      />
                    </Box>
                  }
                  thirdChild={
                    <Controller
                      control={control}
                      name={`tax${ele}`}
                      defaultValue={serviceCharges[ele]?.tax}
                      render={({ field: { value } }) => (
                        <CustomViewOnly
                          label="Amount with GST"
                          value={moneyFormat(getFixedValue(value) ?? 0)}
                        />
                      )}
                    />
                  }
                />
              </Box>
            );
          })}
        <FloatingBar position="sticky">
          <ButtonV1
            onClick={submitCharges}
            title="Submit"
            disabled={checkError()}
          />
        </FloatingBar>
      </>
    );
  },
);

OtherServiceCharges.propTypes = {
  serviceCharges: PropTypes.object,
  submitCharges: PropTypes.func,
  sellerOrderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  shipmentId: PropTypes.number,
};

export default OtherServiceCharges;
