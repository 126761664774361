import { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, TextField, Typography, useTheme } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import PropTypes from "prop-types";

import { SearchBar, DatePickerV1, ButtonV1, DropdownV1 } from "components";

import { getCall_v2 } from "services";

const SearchResultBox = ({ companyName, email, gstin }) => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={1} minWidth={180}>
      <Typography sx={{ fontWeight: 600 }}>{companyName}</Typography>
      <Typography variant="body2"> {email}</Typography>
      <Typography variant="body2"> {gstin}</Typography>
    </Box>
  );
};

const Filters = ({ onSubmit, isDateFilter, onReset, resetPagination }) => {
  const [searchDetails, setSearchDetails] = useState();
  const [defaultValue, setDefaultValue] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const filterRef = useRef();
  const buyer = searchParams.get("buyer");
  const { handleSubmit, getValues, control, reset } = useForm({
    mode: "onTouched",
  });

  /**
   * @description - pass filters when user clicks on submit & remove queryParam
   */
  const passFilters = () => {
    let { fromDate, toDate, orderNumber, ledgerType } = getValues();
    fromDate = new Date(fromDate).getTime();
    toDate = new Date(toDate).getTime();
    const {
      gstin = null,
      companyName = "",
      sellerLedgerReference = "",
    } = searchDetails ?? {};
    resetPagination && resetPagination();
    onSubmit &&
      onSubmit(
        fromDate,
        toDate,
        gstin ?? sellerLedgerReference,
        companyName,
        orderNumber?.trim(),
        ledgerType,
      );
  };

  useEffect(() => {
    (async () => {
      if (buyer && searchParams.get("tab") === "buyer") {
        const companyGST = atob(buyer);
        const result = await getSearchResponse(companyGST);
        setDefaultValue(result[0].companyName);
        //this is to set search details so that
        // gstin is saved in case user comes from order details screen
        setSearchDetails(result[0]);

        //delete query param & call api
        if (searchParams.get("buyer")) {
          searchParams.delete("buyer");
          setSearchParams(searchParams);
        }
        let { fromDate, toDate } = getValues();
        fromDate = new Date(fromDate).getTime();
        toDate = new Date(toDate).getTime();
        onSubmit && onSubmit(fromDate, toDate, atob(buyer));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @param {*} searchInput
   * @returns a list of data matching with search input
   */
  const getSearchResponse = async (searchInput) => {
    try {
      const tab = searchParams.get("tab");
      let res;
      switch (tab) {
        case "buyer":
        case "accountReceivable":
        case "MIS": {
          res = await getCall_v2(
            `/oms/customer-info-rtabs/compName/${searchInput}`,
          );
          break;
        }
        case "seller":
        case "accountPayable": {
          res = await getCall_v2(`/oms/seller/search-reference/${searchInput}`);
          break;
        }
        default:
          break;
      }
      return res?.data;
    } catch (err) {
      console.error(`Error in fetching search results, ${err}`);
    }
  };

  /**
   *
   * @param {*} data
   * @description save search results in state
   */
  const passValue = (data) => setSearchDetails(data);

  /**
   * @description - onreset remove query params & clear search field
   */
  const handleReset = () => {
    reset();
    filterRef?.current?.resetValue();
    if (searchParams.has("buyer")) {
      searchParams.delete("buyer");
      setSearchParams(searchParams);
    }
    onReset && onReset();
  };

  return (
    <Box
      as="form"
      onSubmit={handleSubmit(passFilters)}
      sx={{
        p: 6,
        borderRadius: 2,
        border: `solid 1px ${theme.palette.grey[400]}`,
      }}
    >
      <Box display={"flex"} gap={4}>
        {searchParams.get("tab") === "buyer" && (
          <Controller
            control={control}
            name="ledgerType"
            defaultValue={"Buyer"}
            render={({ field: { onChange, value } }) => (
              <DropdownV1
                itemList={["Buyer", "Buyer Mis"]}
                value={value}
                listType="array"
                onChange={onChange}
                label="Ledger type"
                sx={{ width: 200 }}
              />
            )}
          />
        )}
        {searchParams.get("tab") !== "orders" && (
          <SearchBar
            resolver={getSearchResponse}
            displayAttribute="companyName"
            onItemSelect={passValue}
            renderItem={SearchResultBox}
            defaultValue={defaultValue}
            placeholder={"Search here"}
            ref={filterRef}
          />
        )}
        {searchParams.get("tab") === "orders" && (
          <Controller
            control={control}
            name="orderNumber"
            defaultValue={""}
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                value={value}
                label={"Order number"}
                size="small"
              />
            )}
          />
        )}
        {isDateFilter && (
          <>
            <Box width={170}>
              <Controller
                control={control}
                name="fromDate"
                defaultValue={
                  new Date(new Date().setMonth(new Date().getMonth() - 12))
                }
                render={({ field: { onChange, value } }) => (
                  <DatePickerV1
                    inputProps={{
                      placeholder: "From",
                    }}
                    disableFuture={true}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Box>
            <Box width={170}>
              <Controller
                control={control}
                defaultValue={new Date()}
                name="toDate"
                render={({ field: { onChange, value } }) => (
                  <DatePickerV1
                    inputProps={{
                      placeholder: "To",
                    }}
                    disableFuture={true}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Box>
          </>
        )}
        <ButtonV1 type="submit" title="Show results" />
        <ButtonV1 title="Reset" onClick={handleReset} variant="outlined" />
      </Box>
    </Box>
  );
};

Filters.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  isDateFilter: PropTypes.bool,
  resetPagination: PropTypes.func,
};

export default Filters;
