import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";

const BasicDateTimePicker = ({
  label,
  value,
  onChange,
  disabled,
  dateStyle,
  size = "small",
  disablePast = false,
  disableFuture = false,
  minDate,
  ...rest
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        value={value || null}
        disablePast={disablePast}
        disableFuture={disableFuture}
        minDate={minDate && new Date(minDate)}
        onChange={onChange}
        disabled={disabled || false}
        label={label}
        inputFormat="dd/MM/yyyy hh:mm aa"
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              ...(dateStyle && { ...dateStyle }),
            }}
            size={size}
            data-testid="date-picker"
            area-label="Choose date"
          />
        )}
        {...rest}
      />
    </LocalizationProvider>
  );
};

BasicDateTimePicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  dateStyle: PropTypes.object,
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
  size: PropTypes.string,
};

export default BasicDateTimePicker;
