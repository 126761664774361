import { Fragment, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

import { InputField } from "components";
import theme from "themeProvider";
import { pascalCase, getFixedValue, moneyFormat } from "utils";

import { ErrorMsg } from "CustomStyledComponents";
import { customer, invalidCashbackMsg } from "./constants";

const GridTable = ({
  data,
  title,
  type,
  amounts,
  verifyCashback,
  buyerCashback,
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [isInValidCashback, setIsInValidCashback] = useState(false);
  const orderAmount = amounts?.TOA?.amount?.centAmount / 100;
  const { control } = useFormContext();

  return (
    <Box
      borderRadius={2}
      boxShadow={`${theme.palette.grey["200"]} 0px 0px 4px 2px`}
    >
      <Box
        container
        style={{
          backgroundColor: `${theme.palette.grey["A100"]}`,
          padding: 16,
          margin: 0,
        }}
      >
        <Typography variant="h4">{title}</Typography>
      </Box>
      <Box p={4}>
        {Object.entries(data).map(([key, amount], i) => (
          <Fragment key={key}>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  width: "50%",
                  display:
                    type === customer && !buyerCashback ? "none" : "block",
                }}
                fontWeight={600}
              >
                {type === customer
                  ? buyerCashback || ""
                  : pascalCase(key.split("-")[0])}
              </Typography>
              <Controller
                control={control}
                name={`${type}-${key.split("-")[1]}`}
                defaultValue={amount}
                render={({ field: { value, onChange } }) => {
                  return (
                    <InputField
                      InputProps={{ max: 5 }}
                      rest={{ flexDirection: "column" }}
                      value={
                        amount
                          ? `₹${amount}`
                          : isEditable
                          ? getFixedValue(value)
                          : moneyFormat(value)
                      }
                      onChange={(event) => {
                        let value = event.target.value;
                        if (value > orderAmount) {
                          verifyCashback(true);
                          setIsInValidCashback(true);
                        } else {
                          verifyCashback(false);
                          setIsInValidCashback(false);
                        }
                        onChange(value);
                      }}
                      highlightError={isInValidCashback}
                      type={isEditable ? "number" : "text"}
                      placeholder="Enter amount"
                      disabled={true}
                      onBlur={() => setIsEditable(!isEditable)}
                      onFocus={() => setIsEditable(!isEditable)}
                    />
                  );
                }}
              />
            </Box>
            {isInValidCashback && <ErrorMsg>{invalidCashbackMsg}</ErrorMsg>}
          </Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default GridTable;
