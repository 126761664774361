import {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { styled } from "@mui/system";
import { useDispatch } from "react-redux";
import { AddCircle } from "@mui/icons-material";
import { TableCell, Box, Typography, Checkbox } from "@mui/material";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { TableWrapper, TooltipLabel, ButtonV1 } from "components";
import { useFetchData } from "hooks/useFetchData";
import { StyledTableRow, ColumnTitle } from "CustomStyledComponents";
import { appendRupeeIcon, pascalCase, validateNull } from "utils";
import { lineItemTableHeader } from "./constants";
import SellerJODLHoverBlock from "./SellerJODLHoverBlock";
import LineItemSoInput from "./LineItemSoInput";
import ShortCloseModal from "./components/ShortCloseModal";

import { setLineItemsAction } from "dataStore/orderDetails";
import { postCall_v2 } from "services";
import { useToaster, useCategoryTypeCheck } from "hooks";
import { getAttributes } from "utils";

import { formatSourceSellerData, getSellerInfo } from "../makeData";

import { success, error } from "constants";
import theme from "themeProvider";
import { DetailsTab as detailsTab } from "./constants";
import PayoutCreationModal from "./PayoutCreationModal";

const { DELIVERY_IN_PROGRESS, PARTIALLY_DELIVERED } = detailsTab;

const ColumnLabel = styled("p")({
  color: theme.palette.text.primary,
});

const StyledTableCell = styled(TableCell)(() => ({
  "&.MuiTableCell-root": {
    borderBottom: `1px solid ${theme.palette.grey["400"]}`,
  },
}));

/**
 * @description LineItemList component
 */
const LineItemList = forwardRef(({ id, handleFetchOrderDetails }, ref) => {
  const [isAllChecked, setAllChecked] = useState(false);
  const [isPayoutModal, setIsPayoutModal] = useState(false);
  const [selectedLineItems, setSelectedLineItems] = useState([]);
  const [isShortCloseModalOpen, setIsShortCloseModalOpen] = useState(false);
  const [shortCloseData, setShortCloseData] = useState({});
  const { getValues, setValue } = useForm({
    mode: "onTouched",
  });

  const lineItemSoRowRefs = useRef({});
  const shortCloseRef = useRef();

  const [lineItemData, setLineItemData] = useState(null);
  const [, setReRender] = useState(false);

  const dispatch = useDispatch();
  const triggerToaster = useToaster();
  const { isDirect } = useCategoryTypeCheck();
  const orderDetails = useSelector((state) => state.orderDetails);
  const { refetch: getLineItemsData } = useFetchData(
    "line-items",
    `/oms/line-item/order-number/${id}`,
    (res) => {
      dispatch(setLineItemsAction({ lineItemDetails: res.data }));
      setLineItemData(res.data);
      //setLineItemStatus
      // eslint-disable-next-line array-callback-return
      res?.data?.map((ele) => {
        setValue(
          `currentStatus${ele.lineItemId}`,
          ele?.currentStatus?.state ?? "Pending",
        );
      });
    },
  );

  useImperativeHandle(ref, () => ({
    fetchUpdatedLineItems() {
      getLineItemsData();
    },
  }));

  useEffect(() => {
    getLineItemsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShortClose = async (isPayout, inputData) => {
    try {
      let values = getValues();
      const shortCloseDetails = inputData ? inputData : shortCloseData;
      const data = shortCloseDetails?.map((input) => {
        return {
          buyerOrderStatus: orderDetails?.orderStatus,
          customerOrderId: orderDetails?.id,
          lineItemId: input?.lineItemId,
          isPayoutApplicable: isPayout,
          orderNumber: orderDetails?.orderNumber,
          lineItemState: {
            state: values[`currentStatus${input?.lineItemId}`],
          },
          shortClosedQuantity: input?.shortCloseQty,
          reasonForShortClose: input?.reason,
          primaryQuantity: input?.primaryQuantity,
          quantityShipped: input?.quantityShipped,
          doReleasedQuantity: input?.doReleasedQuantity,
        };
      });
      const res = await postCall_v2(
        `/oms/line-item/update-quantity?isPayoutApplicable=${isPayout}`,
        data,
      );
      if (res) {
        triggerToaster(`${data.length} SKU's short closed`, success);
        setIsShortCloseModalOpen(false);
        setSelectedLineItems([]);
        setAllChecked(false);
        handleFetchOrderDetails();
      }
      getLineItemsData();
    } catch (err) {
      triggerToaster(
        err?.data?.detail ?? `Update failed for lineItem. Pls try again`,
        error,
      );
    } finally {
      shortCloseRef?.current?.toggleLoader();
      setIsPayoutModal(false);
      onShortClose();
    }
  };

  /**
   * @description Add SO lineItem from lineItem component
   * through ref
   */
  const addSoLineItem = (lineItemId) =>
    lineItemSoRowRefs.current[lineItemId].ref.handleSoCounter();

  /**
   * @description toggle SO lineItem view
   */
  const toggleSOLineItem = (row) => {
    lineItemSoRowRefs.current[row.lineItemId].isExpanded =
      !lineItemSoRowRefs.current[row.lineItemId].isExpanded;
    setReRender((prev) => !prev);
  };

  /**
   * @description handle all row selection
   */
  const handleParentCheckbox = (e) => {
    const { checked } = e?.target;

    const validShortCloseItems = lineItemData?.filter((lineItem) => {
      const {
        quantityOrdered,
        quantityShortClose,
        quantityShipped,
        doReleasedQuantity,
      } = lineItem ?? {};

      const additionOfShippedAndShortClose =
        +quantityShipped + +quantityShortClose;
      const additionOfDoReleaseAndShortClose =
        +doReleasedQuantity + +quantityShortClose;
      const validShortCloseQty = Math.min(
        additionOfShippedAndShortClose,
        additionOfDoReleaseAndShortClose,
      );

      return (
        !(
          lineItem?.doReleasedQuantity + lineItem?.quantityShortClose >=
          lineItem?.quantityOrdered
        ) &&
        !(
          lineItem?.quantityShipped + lineItem?.quantityShortClose >=
          lineItem?.quantityOrdered
        ) &&
        !(lineItem?.quantityOrdered < lineItem?.quantityDelivered) &&
        quantityOrdered > validShortCloseQty
      );
    });
    const selectedData = checked ? validShortCloseItems : [];
    setAllChecked(e.target.checked);
    setSelectedLineItems(selectedData);
  };

  /**
   * @description handle lineItem row selection
   */
  const handleChildCheckbox = (e, i, lineItem) => {
    let tempSelectedData = [...selectedLineItems];
    if (e.target.checked) {
      tempSelectedData.push(lineItemData[i]);
    } else {
      tempSelectedData = tempSelectedData.filter(
        (item) => item?.lineItemId !== lineItem?.lineItemId,
      );
    }
    setSelectedLineItems(tempSelectedData);
  };

  const onShortClose = () => {
    setIsShortCloseModalOpen(!isShortCloseModalOpen);
    shortCloseRef?.current?.reset();
  };

  const handlePayoutConfirmation = async (isPayout) => {
    await handleShortClose(isPayout);
  };

  const onConfirmShortClose = (inputData) => {
    setShortCloseData(inputData);
    handleShortClose(false, inputData);
  };

  const isValidShortClose = (lineItem) => {
    let disabled = false;
    if (
      lineItem?.doReleasedQuantity + lineItem?.quantityShortClose >=
        lineItem?.quantityOrdered ||
      lineItem?.quantityShipped + lineItem?.quantityShortClose >=
        lineItem?.quantityOrdered ||
      lineItem?.quantityOrdered < lineItem?.quantityDelivered
    ) {
      disabled = true;
    }
    return disabled;
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "calc(100vw - 115px)",
        }}
      >
        <Box display={"flex"} flexDirection={"row"}>
          <Typography sx={{ fontWeight: "bold", marginTop: 3 }}>
            SKU's
          </Typography>
          {!!selectedLineItems?.length && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                border: `1px solid ${theme.palette.grey[400]}`,
                borderRadius: 1,
                height: 40,
                marginLeft: 6,
                paddingLeft: 2,
              }}
            >
              <Typography>
                {`${selectedLineItems?.length} Items selected`}
              </Typography>
              <ButtonV1
                title={"Clear"}
                variant="text"
                onClick={() => {
                  setAllChecked(false);
                  setSelectedLineItems([]);
                }}
              />
            </Box>
          )}
        </Box>
        <ButtonV1
          title={"Short close"}
          onClick={onShortClose}
          style={{ marginBottom: 2 }}
          disabled={
            !selectedLineItems?.length ||
            ![DELIVERY_IN_PROGRESS, PARTIALLY_DELIVERED].includes(
              orderDetails?.orderStatus,
            )
          }
        />
      </Box>
      {!!(lineItemData && lineItemData.length) && (
        <TableWrapper
          wrapperStyle={{
            width: "calc(100vw - 115px)",
            borderRadius: "12px",
            overflowX: "auto",
          }}
          tableHeader={
            <>
              <TableCell>
                <Checkbox
                  checked={isAllChecked}
                  onChange={(e) => handleParentCheckbox(e)}
                />
              </TableCell>
              {lineItemTableHeader?.map((item) => (
                <StyledTableCell key={item}>
                  <ColumnTitle>{item}</ColumnTitle>
                </StyledTableCell>
              ))}
            </>
          }
          tableBody={lineItemData?.map((lineItem, index) => {
            lineItemSoRowRefs.current[lineItem.lineItemId] = lineItemSoRowRefs
              .current[lineItem.lineItemId] || { isExpanded: false };

            return (
              <>
                <StyledTableRow key={lineItem?.lineItemId} id={index}>
                  <StyledTableCell>
                    <Checkbox
                      checked={selectedLineItems.some(
                        (item) => item?.lineItemId === lineItem?.lineItemId,
                      )}
                      onChange={(e) => handleChildCheckbox(e, index, lineItem)}
                      disabled={isValidShortClose(lineItem)}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"flex-start"}
                    >
                      <Box
                        display={"flex"}
                        sx={{ alignItems: "center", gap: 4 }}
                      >
                        <ColumnLabel style={{ width: 100 }}>
                          {lineItem?.productInfo?.name}{" "}
                          {getAttributes(lineItem?.productInfo?.attributes)}
                        </ColumnLabel>
                        <AddCircle
                          style={{
                            cursor: "pointer",
                            display: lineItemSoRowRefs.current[
                              lineItem.lineItemId
                            ].isExpanded
                              ? "block"
                              : "none",
                          }}
                          color="primary"
                          onClick={() => addSoLineItem(lineItem.lineItemId)}
                        />
                      </Box>
                      {isDirect && (
                        <ButtonV1
                          title={
                            lineItemSoRowRefs.current[lineItem.lineItemId]
                              .isExpanded
                              ? "Hide SO Lineitems"
                              : "Show SO Lineitems"
                          }
                          onClick={() => toggleSOLineItem(lineItem)}
                          variant="text"
                          style={{
                            padding: 0,
                            minWidth: "fit-content",
                          }}
                        />
                      )}
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    {lineItem?.currentStatus?.state ?? "-"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {lineItem.sourceSeller ? (
                      <TooltipLabel
                        placement="right"
                        title={
                          <SellerJODLHoverBlock
                            jodlData={getSellerInfo(
                              orderDetails?.childOrders[0],
                              orderDetails,
                            )}
                            sourceSellerData={formatSourceSellerData(
                              lineItem.sourceSeller,
                            )}
                          />
                        }
                        labelChildren={
                          <Typography
                            fontSize={14}
                            fontWeight={"bold"}
                            color={theme.palette.secondary.main}
                            style={{ wordBreak: "break-word" }}
                          >
                            {validateNull(lineItem.sourceSeller?.companyName)}
                          </Typography>
                        }
                      />
                    ) : (
                      pascalCase(lineItem.sellerInfo?.companyName)
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {`${lineItem?.quantityOrdered} ${lineItem.measurementUnitDescription}`}
                    {lineItem?.productInfo?.secondaryQuantity
                      ? ` | ${lineItem?.productInfo?.secondaryQuantity}`
                      : ""}
                  </StyledTableCell>
                  <StyledTableCell>
                    {lineItem?.quantityShipped}&nbsp;{" "}
                    {lineItem?.measurementUnitDescription}
                  </StyledTableCell>
                  <StyledTableCell>
                    {`${lineItem?.doReleasedQuantity ?? 0} ${
                      lineItem?.measurementUnit
                    }`}
                  </StyledTableCell>
                  <StyledTableCell>
                    {appendRupeeIcon(lineItem?.discountedPrice?.displayAmount)}
                  </StyledTableCell>
                  <StyledTableCell>
                    {appendRupeeIcon(
                      lineItem?.deliveredValueWithTax.displayAmount,
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {lineItem?.cashbackDetails?.cashbackRate &&
                    lineItem?.cashbackDetails?.cashbackUom
                      ? `${lineItem?.cashbackDetails?.cashbackRate} / ${lineItem?.cashbackDetails?.cashbackUom}`
                      : "-"}
                  </StyledTableCell>
                  <StyledTableCell>
                    <TooltipLabel
                      placement="bottom"
                      title={
                        <Typography fontSize={12}>
                          {lineItem?.reasonForShortClose}
                        </Typography>
                      }
                      labelChildren={
                        <Typography
                          sx={{
                            fontSize: 14,
                            width: 50,
                            color: theme.palette.secondary.main,
                            fontWeight: "bold",
                          }}
                        >
                          {`${lineItem?.quantityShortClose} ${lineItem?.measurementUnit}`}
                        </Typography>
                      }
                    />
                  </StyledTableCell>
                </StyledTableRow>
                {!!lineItemSoRowRefs.current[lineItem.lineItemId]
                  .isExpanded && (
                  <StyledTableRow>
                    <TableCell colSpan={14}>
                      <Box width={"60%"}>
                        <LineItemSoInput
                          ref={(ref) =>
                            (lineItemSoRowRefs.current[
                              lineItem.lineItemId
                            ].ref = ref)
                          }
                          lineItemId={lineItem?.lineItemId}
                        />
                      </Box>
                    </TableCell>
                  </StyledTableRow>
                )}
              </>
            );
          })}
        />
      )}
      <ShortCloseModal
        open={isShortCloseModalOpen}
        onCloseModal={onShortClose}
        onConfirm={onConfirmShortClose}
        selectedLineItems={selectedLineItems}
        ref={shortCloseRef}
      />
      <PayoutCreationModal
        isShortClosePayout={true}
        open={isPayoutModal}
        onCloseModal={() => setIsPayoutModal(false)}
        onConfirm={handlePayoutConfirmation}
      />
    </>
  );
});

LineItemList.propTypes = {
  id: PropTypes.string,
  handleFetchOrderDetails: PropTypes.func,
};

export default LineItemList;
