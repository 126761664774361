import { Typography } from "@mui/material";

import LedgerTable from "../components/LedgerTable";
import { Timestamp } from "components";

import { pascalCase, appendRupeeIcon } from "utils";

const AccountPayable = () => {
  const tableHeaderData = [
    {
      title: "Posting date",
      field: "lastUpdated",
      render: (ele) => <Timestamp timeStamp={ele?.lastUpdated} />,
    },
    {
      title: "Seller Name",
      field: "name",
      render: (ele) => <Typography> {pascalCase(ele?.name) ?? "-"}</Typography>,
    },
    {
      title: "Transaction id",
      field: "reference",
      render: (ele) => (
        <Typography maxWidth={240} sx={{ overflowWrap: "anywhere" }}>
          {ele?.reference || "-"}
        </Typography>
      ),
    },
    {
      title: "Category",
      field: "category",
      render: (ele) => <Typography>{ele?.category || "-"}</Typography>,
    },
    {
      title: "Balance",
      field: "balance.displayAmount",
      render: (ele) => (
        <Typography>{appendRupeeIcon(ele?.balance?.displayAmount)}</Typography>
      ),
    },
  ];
  return (
    <LedgerTable
      isDateFilter={false}
      tableHeaderData={tableHeaderData}
      title="Account payable"
      exportFileName="AccountPayable"
      apiName="accountPayable"
      apiUrl="/oms/payment/balance?category=SELLER&isBuyerMis=false&isPositive=true"
    />
  );
};
export default AccountPayable;
