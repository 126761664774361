const CUSTOMER_LEDGER = Object.freeze({
  creditHeader: [
    "Credit program",
    "Credit days",
    "Sanctioned limit",
    "Utilized limit",
    "Available limit",
    "Blocked limit",
    "Freeze status",
    "",
  ],
  ledgerHeader: [
    "Document date",
    "Document type",
    "Document no.",
    "Order ID",
    "Description",
    "Due date",
    "Amount",
    "Balance",
  ],
  ledgerFilterType: ["Document type", "Order Id", "Document no."],
});

export default CUSTOMER_LEDGER;
