import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";

import { DISPATCH_ORDER } from "./constants";
import { moneyFormat } from "utils";
import { StatusChip } from "components";
import { getLabelType } from "./utils";

const DispatchOrderRecordDetails = ({
  selectedRecord,
  showPendingQtyLabel,
}) => {
  return (
    <Grid container columns={14}>
      <Grid xs={2}>
        <Typography fontWeight={"bold"} fontSize={14}>
          Transaction status
        </Typography>
        <StatusChip
          label={selectedRecord?.transactionStatus}
          type={getLabelType(selectedRecord?.transactionStatus)}
          style={{ minWidth: 75 }}
        />
      </Grid>
      <Grid xs={2}>
        <Typography fontWeight={"bold"} fontSize={14}>
          Total DO quantity
        </Typography>
        <Typography>
          {`${selectedRecord?.psPlannedQuantity} `}
          {selectedRecord?.psLineItemsDetails?.[0]?.measurementUnit ===
          DISPATCH_ORDER.uomBag
            ? DISPATCH_ORDER.uomBagLabel
            : selectedRecord?.psLineItemsDetails?.[0]?.measurementUnit}
        </Typography>
      </Grid>
      <Grid xs={2}>
        <Typography fontWeight={"bold"} fontSize={14}>
          Dispatched quantity
        </Typography>
        <Typography>
          {`${selectedRecord?.psShippedQuantity ?? 0} `}
          {selectedRecord?.psLineItemsDetails?.[0]?.measurementUnit ===
          DISPATCH_ORDER.uomBag
            ? DISPATCH_ORDER.uomBagLabel
            : selectedRecord?.psLineItemsDetails?.[0]?.measurementUnit}
        </Typography>
      </Grid>
      <Grid xs={2}>
        <Typography fontWeight={"bold"} fontSize={14}>
          Pending quantity
        </Typography>
        <Typography>
          {showPendingQtyLabel(selectedRecord)}
          {/* {`${
            Number(selectedRecord?.psPlannedQuantity ?? 0) -
            Number(selectedRecord?.psShippedQuantity ?? 0)
          } `}
          {selectedRecord?.psLineItemsDetails?.[0]?.measurementUnit ===
          DISPATCH_ORDER.uomBag
            ? DISPATCH_ORDER.uomBagLabel
            : selectedRecord?.psLineItemsDetails?.[0]?.measurementUnit} */}
        </Typography>
      </Grid>
      <Grid xs={2}>
        <Typography fontWeight={"bold"} fontSize={14}>
          Total DO value
        </Typography>
        <Typography>
          {moneyFormat(
            +selectedRecord?.psPlannedShipmentAmount +
              +(selectedRecord?.freightValue ?? 0),
          )}
        </Typography>
      </Grid>
      <Grid xs={2}>
        <Typography fontWeight={"bold"} fontSize={14}>
          Freight value
        </Typography>
        <Typography>
          {moneyFormat(selectedRecord?.freightValue || 0)}
        </Typography>
      </Grid>
      <Grid xs={2}>
        <Typography fontWeight={"bold"} fontSize={14}>
          Material value
        </Typography>
        <Typography>
          {moneyFormat(selectedRecord?.psPlannedShipmentAmount || 0)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DispatchOrderRecordDetails;

DispatchOrderRecordDetails.propTypes = {
  showPendingQtyLabel: PropTypes.func,
  selectedRecord: PropTypes.object,
};
