import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { ExportCSVCTA, Loader, PaginationAction, HyperLink } from "components";
import QuickFilter from "../Components/QuickFilter";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { ColumnTitle, StyledTableRow } from "CustomStyledComponents";

import {
  pascalCase,
  getPaymentStatusColor,
  validateNull,
  formatDate,
} from "utils";

import { ORDER_TRANSACTION_TYPES } from "../constant";

const ROWS_PER_PAGE = 20;

const OrderTable = ({
  res,
  totalCount,
  offset,
  onPageChange,
  onExportData,
  defaultQuickFilter = "today",
  isLoading,
}) => {
  const navigation = useNavigate();

  const tableFields = useMemo(() => {
    return [
      {
        title: "Buyer order no.",
        field: "customerOrderNumber",
        render: (ele) => <HyperLink title={ele?.customerOrderNumber} />,
      },
      {
        title: "Seller order no.",
        field: "orderNumber",
        render: (ele) => ele?.orderNumber,
      },
      {
        title: "Order date",
        field: "orderDate",
        render: (ele) => <span>{formatDate(ele?.buyerOrderDates)}</span>,
      },
      {
        title: "Order type",
        field: "orderType",
        render: (ele) => ele?.currentOrderType,
      },
      {
        title: "Order category",
        field: "orderCategory",
        render: (ele) => ele?.category,
      },
      {
        title: "Order status",
        field: "orderStatus",
        render: (ele) => ele?.orderStatus,
      },
      {
        title: "Payment Type",
        field: "orderPaymentType",
        render: (ele) => validateNull(ele?.orderPaymentType),
      },
      {
        title: "Seller order status",
        field: "childOrderStatus",
        render: (ele) => ele?.childOrderStatus,
      },
      {
        title: "Ops SPOC",
        field: "opsSpoc",
        render: (ele) => validateNull(ele?.opsSpoc),
      },
      {
        title: "Order payment type",
        field: "orderPaymentTransactionType",
        render: (ele) => (
          <Typography>
            {validateNull(
              ORDER_TRANSACTION_TYPES[ele?.orderPaymentTransactionType],
            )}
          </Typography>
        ),
      },
      {
        title: "Payment status",
        field: "paymentStatus",
        render: (ele) => (
          <span
            style={{ color: getPaymentStatusColor(ele?.currentPaymentStatus) }}
          >
            {ele?.currentPaymentStatus}
          </span>
        ),
      },
      {
        title: "Committed delivery",
        field: "committedDeliveryDate",
        render: (ele) => (
          <span> {validateNull(formatDate(ele?.committedDeliveryDates))}</span>
        ),
      },
      {
        title: "Planned delivery",
        field: "scheduledDeliveryDate",
        render: (ele) => (
          <span>{validateNull(formatDate(ele?.scheduledDeliveryDates))}</span>
        ),
      },
      {
        title: "Buyer",
        field: "buyerDetails.companyName",
        render: (ele) => (
          <span>{pascalCase(ele?.buyerDetails?.companyName)}</span>
        ),
      },
      {
        title: "Seller",
        field: "sellerDetails.companyName",
        render: (ele) => ele?.sellerDetails?.companyName,
      },
      {
        title: "Delivery State",
        field: "buyerDetails.shippingAddress.state",
        render: (ele) => ele?.buyerDetails?.shippingState,
      },
    ];
  }, []);

  const navigateToOrderDetail = ({ customerOrderNumber }) => {
    if (customerOrderNumber) {
      navigation(`/order-list/${customerOrderNumber}?tab=orderDetails`);
    }
  };

  const page = Math.abs(offset / ROWS_PER_PAGE);

  return (
    <>
      <QuickFilter defaultFilter={defaultQuickFilter} />
      <Box display="flex" justifyContent="flex-end">
        <ExportCSVCTA
          onExportData={onExportData}
          fileName="order-list"
          tableFields={tableFields}
        />
      </Box>
      <Box>
        <TableContainer
          sx={{
            minWidth: 700,
            maxHeight: "calc(100vh - 120px)",
          }}
          component={Paper}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {tableFields.map((el) => (
                  <TableCell key={el.field}>
                    <ColumnTitle>{el.title}</ColumnTitle>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading || !res?.length ? (
                <StyledTableRow>
                  <TableCell colSpan={tableFields.length} align="center">
                    {isLoading ? (
                      <Loader sx={{ marginTop: 0 }} />
                    ) : (
                      <Typography textAlign={"center"}>
                        No data found
                      </Typography>
                    )}
                  </TableCell>
                </StyledTableRow>
              ) : (
                res?.map((row) => (
                  <StyledTableRow
                    key={row?.customerOrderNumber}
                    onClick={() => navigateToOrderDetail(row)}
                  >
                    {tableFields?.map((el) => (
                      <TableCell key={el?.field}>{el.render(row)}</TableCell>
                    ))}
                  </StyledTableRow>
                ))
              )}
            </TableBody>
            {!!res?.length && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[ROWS_PER_PAGE]}
                    rowsPerPage={ROWS_PER_PAGE}
                    page={page}
                    count={totalCount ?? 0}
                    onPageChange={onPageChange}
                    ActionsComponent={PaginationAction}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default OrderTable;
