import { Box } from "@mui/material";
import { FileDownloadOutlined } from "@mui/icons-material";
import PropTypes from "prop-types";

import { Ledger } from "../constants";
import { useDownload } from "hooks";

const InvoiceDownload = ({ ele }) => {
  const { downloadDocument } = useDownload();

  const handleInvoiceDownload = (data) => {
    const {
      notes: { shipmentNumber, invoiceNumber, offOmsType, payoutId },
    } = data;

    const fileName = `invoice_${payoutId}`;

    if (offOmsType === Ledger.invoiceAndSellerPayouts) {
      const params = {
        payoutId,
      };
      const downloadUrl = "/oms/off-oms-payout/document/get";
      downloadDocument(downloadUrl, fileName, params);
    } else if (offOmsType === Ledger.creditNotesAndSales) {
      const params = {
        payoutId,
      };
      const downloadUrl =
        "/oms/off-oms-payout/document/credit-and-sales-note/get";
      downloadDocument(downloadUrl, fileName, params);
    } else {
      const params = {
        shipmentNumber,
        fileType: "INVOICE",
        fileId: null,
        invoiceNumber,
      };
      const downloadUrl = "/oms/shipment/documents/invoice";
      downloadDocument(downloadUrl, "INVOICE", params);
    }
  };

  return (
    <>
      {ele?.notes?.invoiceNumber && ele?.notes?.invoiceNumber !== "-" ? (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          {ele.notes.invoiceNumber}
          <FileDownloadOutlined
            style={{ width: 16, marginLeft: 6 }}
            color={"primary"}
            onClick={() => handleInvoiceDownload(ele)}
          />
        </Box>
      ) : (
        "-"
      )}
    </>
  );
};

InvoiceDownload.propTypes = {
  ele: PropTypes.object,
};

export default InvoiceDownload;
