import { Box, Typography } from "@mui/material";

import LedgerTable from "../components/LedgerTable";
import { Timestamp } from "components";

import { pascalCase, appendRupeeIcon, validateNull } from "utils";

const AccountReceivable = () => {
  const tableHeaderData = [
    {
      title: "Posting date",
      field: "lastUpdated",
      render: (ele) => <Timestamp timeStamp={ele?.lastUpdated} />,
    },
    {
      title: "Customer name",
      field: "name",
      render: (ele) => (
        <Box maxWidth={280}>
          <Typography fontSize={14}>
            {validateNull(pascalCase(ele?.name))}
          </Typography>
        </Box>
      ),
    },
    {
      title: "GSTIN",
      field: "reference",
      render: (ele) => (
        <Typography fontSize={14}>{validateNull(ele?.reference)}</Typography>
      ),
    },
    {
      title: "Category",
      field: "category",
      render: (ele) => (
        <Typography fontSize={14}>
          {validateNull(pascalCase(ele?.category))}
        </Typography>
      ),
    },

    {
      title: "Balance",
      field: "balance.displayAmount",
      render: (ele) => (
        <Typography fontSize={14}>
          {appendRupeeIcon(ele?.balance?.displayAmount)}
        </Typography>
      ),
    },
  ];

  return (
    <LedgerTable
      isDateFilter={false}
      tableHeaderData={tableHeaderData}
      apiUrl="/oms/payment/balance?category=BUYER&isBuyerMis=true&isPositive=false"
    />
  );
};
export default AccountReceivable;
