import { useState, useEffect, useMemo } from "react";
import { Controller, useForm, FormProvider } from "react-hook-form";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";

import { DatePickerV1, ButtonV1, DropdownV1, TextField } from "components";
import { GridSkeleton } from "CustomStyledComponents";
import ShipmentCreationTable from "./ShipmentCreationTable";
import { ColumnView, ErrorMsg } from "../ShipmentCustomComponents";

import { useFetchData, useToaster, useCategoryTypeCheck } from "hooks";
import { postCall, postCall_v2, putCall } from "services";
import { validateLineItem } from "./validateLineItem";
import { defaultShipmentMode, error, orderStatus, success } from "../constants";
import { createShipmentModel, warehouseNameFix } from "../DataModel";
import { offsetDays } from "utils";
import { ShipmentCreateSchema } from "../TestSchema";
import { getValidLineItems } from "../shipmentCalculationUtil";

const JOTS = "JOTS";
const BUYER_PICKUP = "Buyer Pickup";

const CreateShipment = ({ shipmentData, closeModal }) => {
  const [orderIdList, setOrderIdList] = useState([]);
  const [shipmentModeList, setShipmentModeList] = useState([]);
  const [deliveryType, setDeliveryType] = useState(null);
  const [shipmentInfo, setShipmentInfo] = useState(null);
  const [releasedQty, setReleasedQty] = useState(null);

  const [isLoading, setLoading] = useState(false);
  const orderDetails = useSelector((state) => state?.orderDetails);

  const tenDaysOffsetDate = useMemo(() => offsetDays(10), []);
  const validLineItem = useMemo(
    () => getValidLineItems(shipmentInfo),
    [shipmentInfo],
  );
  const triggerToaster = useToaster();
  const { isDirect, isDistributed, isPborDirect } = useCategoryTypeCheck();

  const methods = useForm({
    mode: "onTouched",
    defaultValues: {
      lineItemsDetails: [],
    },
    resolver: yupResolver(ShipmentCreateSchema(orderDetails?.category)),
  });

  const {
    handleSubmit,
    getValues,
    control,
    watch,
    formState: { errors },
    setValue,
  } = methods;
  const getShipmentMode = useFetchData(
    "shipment-mode",
    "/oms/master-data/MODE_OF_SHIPMENT",
    (res) => {
      setShipmentModeList(res?.data);
    },
  );

  const watchDeliveryType = watch("deliveryType");
  const isModeOfShipmentNA = isDirect && watchDeliveryType === BUYER_PICKUP;

  const getDeliveryType = useFetchData(
    "delivery-type",
    "/oms/master-data/DELIVERY_TYPE",
    (res) => {
      setDeliveryType(res?.data);
    },
  );

  const fetchReleasedDO_Qty = async () => {
    try {
      const ids = orderDetails?.lineItemDetails?.map((ele) => ele?.lineItemId);
      const { data } = await postCall_v2("/oms/plant-supply/release/quantity", {
        lineItemIds: ids ?? [],
      });
      const releaseMapping = {};
      data?.plantSupplyWithReleaseQuantityDetails?.forEach((ele) => {
        releaseMapping[ele?.lineItemId] = ele;
      });
      setReleasedQty(releaseMapping);
    } catch (ex) {
      console.log(`Exception in fetching released qty`);
    }
  };

  useEffect(() => {
    (async () => {
      if (shipmentData && shipmentData.length) {
        const orders = [];
        // eslint-disable-next-line array-callback-return
        shipmentData.map((ele) => {
          orders.push({
            label: ele?.["sellerInfo"]?.["companyName"],
            value: ele?.["sellerOrderNumber"],
          });
        });
        setOrderIdList(orders);
      }

      getShipmentMode.refetch();
      getDeliveryType.refetch();
      setValue("scheduledDispatchDateCondition", tenDaysOffsetDate);
      // await fetchReleasedDO_Qty();
      if (isPborDirect) {
        await fetchReleasedDO_Qty();
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPborDirect]);

  const fetchLineItems = () => {
    const orderId = getValues("orderId");

    const selectedShipment = shipmentData.find(
      (ele) => ele.sellerOrderNumber === orderId,
    );

    const data = warehouseNameFix(selectedShipment);
    setShipmentInfo(data);
  };

  const handleError = (err) => {
    if (err && err?.data && err?.data?.detail) {
      triggerToaster(err?.data?.detail, error);
    } else {
      triggerToaster("Please try again later, shipment creation failed", error);
    }
  };

  /**
   * @description - creates shipment by ensuring all checks
   */
  const createShipment = async () => {
    setLoading(true);
    const validateLineItemDetails = validateLineItem(orderDetails, getValues());
    if (validateLineItemDetails?.isValidLineItem) {
      const res = createShipmentModel(getValues(), shipmentInfo);
      const data = await postCall("/oms/shipment", res, {}, handleError);
      if (data) {
        triggerToaster(`Shipment created successfully`, success);
        closeModal();

        if (orderDetails.orderStatus === orderStatus.placed) {
          await putCall(
            "/oms/order/order-update",
            {
              orderStatus: orderStatus.deliveryInProgress,
              orderNumber: orderDetails.orderNumber,
            },
            {},
            () => {
              triggerToaster(
                `Update order status failed for order-${orderDetails.orderNumber}. Pls try again`,
                error,
              );
            },
          );
        }
      }
    } else {
      triggerToaster(validateLineItemDetails?.message, error);
    }
    setLoading(false);
  };

  /**
   * @description Change default value of shipment mode according to deliveryType
   */
  const handleDefaultMode = (deliveryType) => {
    if (isDirect && deliveryType === BUYER_PICKUP) {
      setValue("shipmentMode", "NA");
    } else {
      setValue("shipmentMode", "Road");
    }
  };

  /**
   * @description get ShipmentModeList according to deliveryType
   */
  const getShipmentModeList = () => {
    if (isModeOfShipmentNA) {
      return shipmentModeList;
    } else {
      return shipmentModeList.filter((shipmentMode) => shipmentMode !== "NA");
    }
  };

  return (
    <>
      <Typography variant="h5" mb={4}>
        Create new shipment
      </Typography>
      <FormProvider {...methods}>
        <Box as="form" onSubmit={handleSubmit(createShipment)}>
          <Box mb={12}>
            <Controller
              control={control}
              name="orderId"
              defaultValue={""}
              render={({ field: { value, onChange } }) => (
                <DropdownV1
                  placeholder="Seller name"
                  itemList={orderIdList}
                  sx={{ width: "20%" }}
                  value={value}
                  onChange={(evt) => {
                    onChange(evt?.target?.value);
                    fetchLineItems();
                  }}
                />
              )}
            />
          </Box>
          {!!shipmentInfo && (
            <>
              {validLineItem.length ? (
                <>
                  <Box mb={8} overflow={"scroll"} maxHeight={270}>
                    <ShipmentCreationTable
                      lineItemsDetails={validLineItem}
                      releasedQty={releasedQty}
                    />
                  </Box>

                  <>
                    <GridSkeleton
                      style={{ marginBottom: 8 }}
                      firstChild={
                        <Box>
                          <Typography
                            style={{ marginBottom: 8 }}
                            fontWeight={600}
                          >
                            Mode of shipment
                          </Typography>

                          <Controller
                            control={control}
                            defaultValue={defaultShipmentMode}
                            name="shipmentMode"
                            render={({ field: { onChange, value } }) => (
                              <>
                                <DropdownV1
                                  itemList={getShipmentModeList()}
                                  sx={{ width: "50%" }}
                                  onChange={onChange}
                                  value={value}
                                  listType="array"
                                />
                                <ErrorMsg msg={errors?.shipmentMode?.message} />
                              </>
                            )}
                          />
                        </Box>
                      }
                      secondChild={
                        <Box>
                          <Typography
                            style={{ marginBottom: 8 }}
                            fontWeight={600}
                          >
                            Delivery type
                          </Typography>
                          <Controller
                            control={control}
                            name="deliveryType"
                            render={({ field: { onChange, value } }) => (
                              <>
                                <DropdownV1
                                  placeholder="Select delivery type"
                                  itemList={deliveryType}
                                  onChange={(e) => {
                                    onChange(e);
                                    handleDefaultMode(e?.target?.value);
                                  }}
                                  valueKey="state"
                                  value={value}
                                  sx={{ width: "50%" }}
                                />
                                <ErrorMsg msg={errors.deliveryType?.message} />
                              </>
                            )}
                          />
                        </Box>
                      }
                      thirdChild={
                        <Box>
                          <Typography
                            style={{ marginBottom: 8 }}
                            fontWeight={600}
                          >
                            Scheduled dispatch date
                          </Typography>
                          <Controller
                            control={control}
                            name="scheduledDispatchDate"
                            defaultValue={null}
                            render={({ field: { onChange, value } }) => (
                              <>
                                <DatePickerV1
                                  dateStyle={{ width: "60%" }}
                                  onChange={onChange}
                                  labelStyle={{
                                    marginBottom: 8,
                                  }}
                                  minDate={tenDaysOffsetDate}
                                  value={value}
                                  rest={{ flexDirection: "column" }}
                                />
                                <ErrorMsg
                                  msg={errors.scheduledDispatchDate?.message}
                                />
                              </>
                            )}
                          />
                        </Box>
                      }
                    />

                    <GridSkeleton
                      firstChild={
                        // ToDo[OMS-7260] Commented Pickup location
                        // <Box>
                        //   <Typography
                        //     style={{ marginBottom: 8 }}
                        //     fontWeight={600}
                        //   >
                        //     Pick-up location
                        //   </Typography>
                        //   <Controller
                        //     control={control}
                        //     name="warehouseDetail"
                        //     defaultValue={shipmentInfo?.warehouses[0]?.name}
                        //     render={({
                        //       field: { value, onChange, onBlur },
                        //     }) => (
                        //       <>
                        //         <DropdownV1
                        //           itemList={shipmentInfo?.warehouses}
                        //           onChange={onChange}
                        //           value={value}
                        //           valueKey="name"
                        //           labelKey="name"
                        //           sx={{ width: "50%" }}
                        //           onBlur={onBlur}
                        //         />
                        //         <ErrorMsg
                        //           msg={errors.warehouseDetail?.message}
                        //         />
                        //       </>
                        //     )}
                        //   />
                        // </Box>
                        isDistributed &&
                        watchDeliveryType === JOTS && (
                          <Box>
                            <Controller
                              control={control}
                              name="driverName"
                              render={({ field: { value, onChange } }) => (
                                <ColumnView label={"Driver name"} bold>
                                  <TextField
                                    onChange={onChange}
                                    value={value}
                                    size={"small"}
                                  />
                                </ColumnView>
                              )}
                            />
                            <ErrorMsg msg={errors.driverName?.message} />
                          </Box>
                        )
                      }
                      secondChild={
                        isDistributed &&
                        watchDeliveryType === JOTS && (
                          <Box>
                            <Controller
                              control={control}
                              name="driverNo"
                              render={({ field: { value, onChange } }) => (
                                <ColumnView label={"Driver number"} bold>
                                  <TextField
                                    onChange={onChange}
                                    value={value}
                                    size={"small"}
                                  />
                                </ColumnView>
                              )}
                            />
                            <ErrorMsg msg={errors.driverNo?.message} />
                          </Box>
                        )
                      }
                      thirdChild={
                        isDistributed &&
                        watchDeliveryType === JOTS && (
                          <Box>
                            <Controller
                              control={control}
                              name="truckNo"
                              render={({ field: { value, onChange } }) => (
                                <ColumnView label={"Truck number"} bold>
                                  <TextField
                                    onChange={onChange}
                                    value={value}
                                    size={"small"}
                                  />
                                </ColumnView>
                              )}
                            />
                            <ErrorMsg msg={errors.truckNo?.message} />
                          </Box>
                        )
                      }
                    />

                    <GridSkeleton
                      firstChild={
                        isDistributed &&
                        watchDeliveryType === JOTS && (
                          <Box>
                            <Controller
                              control={control}
                              name="truckReceiptNumber"
                              render={({ field: { value, onChange } }) => (
                                <ColumnView label={"Truck receipt no."} bold>
                                  <TextField
                                    onChange={onChange}
                                    value={value}
                                    size={"small"}
                                  />
                                </ColumnView>
                              )}
                            />
                            <ErrorMsg
                              msg={errors.truckReceiptNumber?.message}
                            />
                          </Box>
                        )
                      }
                    />
                  </>

                  <Box
                    style={{ display: "flex", justifyContent: "center" }}
                    m={4}
                  >
                    <ButtonV1
                      type="submit"
                      title={"Submit"}
                      loader={isLoading}
                    />
                  </Box>
                </>
              ) : (
                <Typography fontWeight={600} textAlign={"center"}>
                  No Line item available for shipment
                </Typography>
              )}
            </>
          )}
        </Box>
      </FormProvider>
    </>
  );
};

export default CreateShipment;
