import { useState, useEffect, useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";

import { useFetchData } from "hooks/useFetchData";
import { OrderFilter } from "./OrderFilter";
import { getCall } from "services";
import OrderTable from "./OrderTable";
import { updateQuery } from "../utils";
import { getFormattedListData } from "../makeData";
import { setorderListPathParams } from "dataStore/orderListPathParams";

const FullPaymentOrdersTab = () => {
  const [ordersData, setOrdersData] = useState([]);
  const [disableReset, setDisableReset] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const [disableListCall, setDisableListCall] = useState(false);

  const businessList = useSelector((state) => state.userData.businessUnits);

  const orderHeading = searchParams.get("filterType");
  const orderFilterDuration = searchParams.get("filterValue");
  const page = searchParams.get("page");

  const allowedBU = useMemo(() => {
    if (Array.isArray(businessList)) {
      return businessList?.map(({ name }) => name).join(",");
    } else {
      return "";
    }
  }, [businessList]);

  const onSuccess = (res) => {
    setOrdersData(res?.data);
  };

  const masterApiQuery =
    search
      .replace("filterValue=all", "filterValue=")
      .replace(`&page=${page}`, "") + "&";

  const { refetch, isFetching } = useFetchData(
    "order-list",
    `/oms/order/list${masterApiQuery ? masterApiQuery : "?"}offset=${
      page * 20
    }&limit=20`,
    onSuccess,
  );

  useEffect(
    () => {
      if (orderFilterDuration === null) {
        setSearchParams("filterValue=today");
      } else if (
        disableListCall ||
        typeof searchParams.get("customerOrderNumber") === "string"
      ) {
        callFilterApi();
      } else {
        refetch();
      }
      dispatch(setorderListPathParams(searchParams.toString()));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search],
  );

  const callFilterApi = async () => {
    setIsLoading(true);
    const url = `/oms/order/orderInfo?offset=${page * 20}&limit=20`;
    let params = {
      businessUnit: allowedBU || "",
    };
    searchParams.forEach((value, key) => {
      if (key === "page") {
        return;
      }
      if (key === "filterValue" && value === "all") {
        params[key] = undefined;
      } else {
        params[key] = value || undefined;
      }
    });
    const result = await getCall(url, params);
    let formattedResponse = getFormattedListData(result);
    if (result) {
      setOrdersData(formattedResponse);
    }
    setIsLoading(false);
  };

  const handlePageChange = (_event, page) => {
    const updatedQuery = updateQuery(searchParams, { page });
    setSearchParams(updatedQuery);
  };

  const handleExportData = async () => {
    const result = await getCall(
      `/oms/order/orderInfo?offset=0&limit=1000&${
        search?.replace("?", "") || ""
      }&businessUnit=${allowedBU || ""}`,
    );
    if (result) {
      return result?.response;
    }
  };

  const disableListCallFn = (evt) => setDisableListCall(evt);

  return (
    <Box>
      <OrderFilter
        disableReset={disableReset}
        setDisableReset={setDisableReset}
        refetchList={() => {
          setSearchParams(
            `filterValue=${orderFilterDuration}&filterType=${orderHeading}`,
          );
        }}
        disableListCallFn={(evt) => disableListCallFn(evt)}
      />
      <Box py={4}>
        <OrderTable
          res={ordersData?.response}
          totalCount={ordersData?.totalCount}
          offset={ordersData?.offset}
          onPageChange={handlePageChange}
          onExportData={handleExportData}
          defaultQuickFilter={orderFilterDuration || "today"}
          isLoading={isFetching || isLoading}
        />
      </Box>
    </Box>
  );
};
export default FullPaymentOrdersTab;
