import { Box, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";

const LedgerSummary = ({ balanceAmount, netBalanceAmount, blockedAmount }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.info.lightBlueBackround,
        padding: 4,
        marginBottom: 4,
        borderRadius: 1,
        maxWidth: "35%",
      }}
    >
      <Typography sx={{ fontWeight: "bold", marginBottom: 2 }}>
        Ledger balance details
      </Typography>
      <Typography>
        <Typography variant="body2" component="span" fontWeight={"bold"}>
          Balance:
        </Typography>
        {balanceAmount} |{" "}
        <Typography variant="body2" component="span" fontWeight={"bold"}>
          Net balance:
        </Typography>
        {netBalanceAmount}
      </Typography>
      <Typography
        sx={{ fontSize: 12 }}
      >{`${blockedAmount} is blocked against the active orders`}</Typography>
    </Box>
  );
};

LedgerSummary.propTypes = {
  balanceAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  netBalanceAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  blockedAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default LedgerSummary;
