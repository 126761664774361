import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import PropTypes from "prop-types";

import { DISPATCH_ORDER } from "./constants";
import { moneyFormat } from "utils";

const { dispatchOrderLineItemHeader } = DISPATCH_ORDER;

const DispatchRecordBreakup = ({
  isCollapseOpen,
  item,
  showPendingQtyLabelInDetailsModel,
  measurementUnit,
}) => {
  return (
    <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
      <Box>
        <Table>
          <TableHead>
            <TableRow>
              {dispatchOrderLineItemHeader.map((ele) => (
                <TableCell>{ele}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {item?.shipmentLinkList.length ? (
              item?.shipmentLinkList?.map((shipment) => {
                return (
                  <TableRow>
                    <TableCell>{shipment?.shipmentNumber}</TableCell>
                    <TableCell>
                      {shipment?.releasedQuantity}
                      {measurementUnit}
                    </TableCell>
                    <TableCell>
                      {`${shipment?.shippedQuantity} `}
                      {measurementUnit}
                    </TableCell>
                    <TableCell>
                      {showPendingQtyLabelInDetailsModel(
                        shipment?.pendingQuantity,
                        measurementUnit,
                      )}
                      {/* {decimalRoundOff(
                                                releasedQuantity?.[index] -
                                                  shipment?.shippedQuantity,
                                              )}{" "}
                                              {/* {`${
                                                releasedQuantity?.[index] -
                                                shipment?.shippedQuantity
                                              } `} */}
                      {/* {measurementUnit} */}
                    </TableCell>
                    <TableCell>{moneyFormat(shipment?.shippedValue)}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={5} sx={{ textAlign: "center" }}>
                  No data found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
    </Collapse>
  );
};

export default DispatchRecordBreakup;

DispatchRecordBreakup.propTypes = {
  showPendingQtyLabelInDetailsModel: PropTypes.func,
  item: PropTypes.object,
  isCollapseOpen: PropTypes.bool,
  measurementUnit: PropTypes.string,
};
