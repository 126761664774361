import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Box, TextField, Typography, useTheme } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import PropTypes from "prop-types";

import { ButtonV1, DropdownV1, ModalBox } from "components";
import { ErrorMsgV1 } from "CustomStyledComponents";

import { useFetchData } from "hooks";
import { decimalRoundOff } from "utils";
import { reasonTypes } from "constants";

const lineItemsSchema = Yup.object().shape({
  lineItems: Yup.array().of(
    Yup.object().shape({
      reason: Yup.string().required("Reason is required"),
      shortCloseQty: Yup.number().test({
        name: "shortCloseQty",
        message: "Short close quantity should be greater than 0.",
        test(value, ctx) {
          if (+value <= 0) {
            return false;
          }
          return true;
        },
      }),
    }),
  ),
});

const RowViewOnly = ({ label, value }) => (
  <Box sx={{ display: "flex", flexDirection: "row", padding: 1.5 }}>
    <Box sx={{ width: 100 }}>
      <Typography>{label}</Typography>
    </Box>
    <Typography sx={{ fontWeight: "bold" }}>{value}</Typography>
  </Box>
);

const ShortCloseModal = forwardRef(
  ({ open, onCloseModal, onConfirm, selectedLineItems }, ref) => {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);

    const {
      control,
      getValues,
      setValue,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm({
      mode: "onTouched",
      resolver: yupResolver(lineItemsSchema),
    });

    // hook to expose function for enable disable loader
    useImperativeHandle(
      ref,
      () => ({
        toggleLoader: () => {
          setLoading(false);
        },
        reset: () => reset(),
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    const { data: shortCloseReasons, refetch: fetchShortCloseReasons } =
      useFetchData(
        "short-close-reasons",
        `/oms/rejection/get-reasons-by-type?type=${reasonTypes.SHORT_CLOSE}`,
      );

    const getSelectedItemData = (inputLineItemId) => {
      return selectedLineItems?.find(
        (item) => item?.lineItemId === inputLineItemId,
      );
    };

    // Enable loader and pass short close value
    const onShortClose = () => {
      setLoading(true);
      const inputData = getValues("lineItems");
      const formattedInput = inputData?.map((input) => {
        return {
          ...input,
          primaryQuantity: getSelectedItemData(input?.lineItemId)
            ?.quantityOrdered,
          quantityShipped: getSelectedItemData(input?.lineItemId)
            ?.quantityShipped,
          doReleasedQuantity: getSelectedItemData(input?.lineItemId)
            ?.doReleasedQuantity,
        };
      });
      onConfirm(formattedInput);
    };

    const getDefaultShortCloseValue = (
      quantityOrdered,
      quantityShipped,
      doReleasedQuantity,
      quantityShortClose = 0,
    ) => {
      const availableValue = +quantityShortClose + +quantityShipped;
      const availableValueWithDO = +quantityShortClose + +doReleasedQuantity;

      const shortClosedQuantity = Math.min(
        +quantityOrdered - +quantityShipped,
        +quantityOrdered - +doReleasedQuantity,
        +quantityOrdered - +availableValue,
        +quantityOrdered - +availableValueWithDO,
      );
      return decimalRoundOff(shortClosedQuantity, 4);
    };

    useEffect(() => {
      fetchShortCloseReasons();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <ModalBox open={open} onCloseModal={onCloseModal}>
        <Box as="form" onSubmit={handleSubmit(onShortClose)}>
          <Box
            sx={{
              borderBottom: `1px solid ${theme.palette.grey["400"]}`,
              padding: 4,
              paddingTop: 0,
              paddingLeft: 0,
            }}
          >
            <Typography fontWeight={600} fontSize={20} marginTop={-2}>
              {"Short close"}
            </Typography>
          </Box>
          {(selectedLineItems?.length ? selectedLineItems : []).map(
            (lineItem, index) => {
              const {
                lineItemId,
                quantityShipped,
                quantityOrdered,
                productInfo,
                measurementUnit,
                doReleasedQuantity = 0,
                quantityShortClose,
              } = lineItem;
              return (
                <Box
                  key={lineItemId}
                  sx={{
                    borderBottom: `1px solid ${theme.palette.grey["400"]}`,
                    padding: 4,
                    backgroundColor: theme.palette.grey["100"],
                    borderRadius: 1,
                    marginTop: 4,
                  }}
                >
                  <RowViewOnly label="SKU Name" value={productInfo?.sku} />
                  <RowViewOnly
                    label="Ordered quantity"
                    value={`${quantityOrdered} ${measurementUnit}`}
                  />
                  <RowViewOnly
                    label="Shipped quantity"
                    value={`${quantityShipped} ${measurementUnit}`}
                  />

                  <RowViewOnly
                    label="Cancelled quantity"
                    value={`${quantityShortClose} ${measurementUnit}`}
                  />
                  <Box
                    sx={{ display: "flex", flexDirection: "row", padding: 1.5 }}
                  >
                    <Box sx={{ width: 100 }}>
                      <Typography>Short close</Typography>
                    </Box>
                    <Box>
                      <Controller
                        control={control}
                        name={`lineItems.${index}.shortCloseQty`}
                        defaultValue={getDefaultShortCloseValue(
                          quantityOrdered,
                          quantityShipped,
                          doReleasedQuantity,
                          quantityShortClose,
                        )}
                        render={({ field: { value, onChange } }) => (
                          <TextField
                            value={value}
                            onChange={(ev) => {
                              const newValue = Number(ev.target.value);
                              if (
                                newValue <=
                                getDefaultShortCloseValue(
                                  quantityOrdered,
                                  quantityShipped,
                                  doReleasedQuantity,
                                  quantityShortClose,
                                )
                              ) {
                                onChange(ev);
                              }
                            }}
                            size="small"
                          />
                        )}
                      />
                    </Box>
                  </Box>

                  <ErrorMsgV1
                    msg={errors?.lineItems?.[index]?.shortCloseQty?.message}
                    style={{ marginLeft: 8 }}
                  />
                  <Box sx={{ padding: 2 }}>
                    <Typography sx={{ marginBottom: 1 }}>
                      Reason
                      <Typography
                        component="span"
                        sx={{ color: theme.palette.error.dark, marginLeft: 1 }}
                      >
                        *
                      </Typography>
                    </Typography>
                    <Controller
                      control={control}
                      name={`lineItems.${index}.reason`}
                      defaultValue={""}
                      render={({ field: { value, onChange } }) => (
                        <DropdownV1
                          placeholder="Select"
                          onChange={(e) => {
                            onChange(e?.target?.value);
                            setValue(
                              `lineItems.${index}.lineItemId`,
                              lineItemId,
                            );
                          }}
                          itemList={shortCloseReasons?.data}
                          value={value}
                          valueKey="reason"
                          listType="object"
                        />
                      )}
                    />
                    <ErrorMsgV1
                      msg={errors?.lineItems?.[index]?.reason?.message}
                    />
                  </Box>
                </Box>
              );
            },
          )}
          <Box display={"flex"} justifyContent={"space-between"} paddingTop={4}>
            <Typography>
              Fields marked
              <Typography
                sx={{ color: theme.palette.error.dark, marginLeft: 1 }}
                component="span"
              >
                *
              </Typography>
              are required
            </Typography>
            <ButtonV1 title={"Short close"} loader={loading} type="submit" />
          </Box>
        </Box>
      </ModalBox>
    );
  },
);

ShortCloseModal.propTypes = {
  selectedLineItems: PropTypes.array,
  onCloseModal: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ShortCloseModal;
