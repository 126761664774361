import React, { useCallback } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { ButtonV1, DateTimePicker, DropdownV1 } from "components";
import { ErrorMsgV1 } from "CustomStyledComponents";

import { doSchema } from "../TestSchema";
import { getAttributes, moneyFormat } from "utils";
import { DISPATCH_ORDER } from "../constants";

const ConfirmPaymentModal = ({
  selectedLineItemList = [],
  estimatedPrice,
  onCloseModal,
  onRequestPayment,
  selectedLineItemAddress,
}) => {
  const {
    control,
    getValues,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm({
    mode: "onTouch",
    resolver: yupResolver(doSchema),
  });

  /**
   * @description payment request fn invoked
   */
  const handleConfirmModal = useCallback(async () => {
    await onRequestPayment(getValues());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onRequestPayment]);

  return (
    <Box as="form" onSubmit={handleSubmit(handleConfirmModal)}>
      <Typography variant="h4">Payment request</Typography>
      <Divider />
      <Grid container py={4}>
        <Grid xs={3.5}>
          <Typography fontWeight={600} mb={1}>
            Pick-up slot: From
          </Typography>
          <Controller
            control={control}
            name={`pickUpSlotFrom`}
            render={({ field: { value, onChange } }) => (
              <DateTimePicker
                dateStyle={{ width: "90%" }}
                value={value}
                onChange={onChange}
              />
            )}
          />
          <ErrorMsgV1 msg={errors?.pickUpSlotFrom?.message} />
        </Grid>
        <Grid xs={3.5}>
          <Typography fontWeight={600} mb={1}>
            Pick-up slot: To
          </Typography>
          <Controller
            control={control}
            name={`pickUpSlotTo`}
            render={({ field: { value, onChange } }) => (
              <DateTimePicker
                dateStyle={{ width: "90%" }}
                value={value}
                onChange={onChange}
              />
            )}
          />
          <ErrorMsgV1 msg={errors?.pickUpSlotTo?.message} />
        </Grid>
        <Grid xs={2}>
          <Typography fontWeight={600} mb={1}>
            Seller name
          </Typography>
          <Typography>Heena Steels</Typography>
        </Grid>
        <Grid xs={3}>
          <Typography fontWeight={600} mb={1}>
            Seller address
          </Typography>
          <Controller
            control={control}
            name={"sellerAddress"}
            defaultValue={selectedLineItemAddress[0]}
            render={({ field: { value, onChange } }) => (
              <DropdownV1
                sx={{
                  width: "80%",
                }}
                itemList={selectedLineItemAddress}
                listType="array"
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Grid>
      </Grid>
      <Paper elevation={1} sx={{ marginBottom: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              {DISPATCH_ORDER.paymentRequest.map((head) => (
                <TableCell>{head}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedLineItemList?.map((lineItem) => {
              const totalPlannedQty = lineItem.plannedQty;
              return (
                <TableRow>
                  <TableCell>
                    {lineItem.skuName}
                    {getAttributes(lineItem?.attributes)}
                  </TableCell>
                  <TableCell>
                    {lineItem.totalQuantity} {lineItem.uom}
                  </TableCell>
                  <TableCell>
                    {totalPlannedQty} {lineItem.uom}
                  </TableCell>
                  <TableCell>
                    {moneyFormat(totalPlannedQty * lineItem.ratePerUnit, 0)}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell colSpan={DISPATCH_ORDER.dispatchTableHeader.length}>
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    flex: 1,
                    gap: 1,
                  }}
                >
                  Total shipment value :<b>{moneyFormat(estimatedPrice)}</b>
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
      <Box
        display={"flex"}
        sx={{
          justifyContent: "flex-end",
          gap: 4,
          alignItems: "center",
        }}
      >
        {/* <Box
          display={"flex"}
          sx={{
            flex: 1,
            visibility: totalPayableAmount > 0 ? "visible" : "hidden",
          }}
        >
          <Typography
            display={"flex"}
            sx={{
              backgroundColor: theme.palette.grey["A100"],
              padding: 2,
              borderRadius: 0.5,
              alignItems: "center",
              gap: 2,
              fontSize: 14,
            }}
          >
            <SvgIcon name="info" width={15} height={15} /> Note: Customer will
            receive a mail to pay
            <Typography fontWeight={600}>
              {moneyFormat(totalPayableAmount)}
            </Typography>
          </Typography>
        </Box> */}

        <ButtonV1 title={"Cancel"} variant="outlined" onClick={onCloseModal} />
        <ButtonV1 title={"Confirm"} type="submit" disabled={!isDirty} />
      </Box>
    </Box>
  );
};

ConfirmPaymentModal.propTypes = {
  selectedLineItemList: PropTypes.array,
  ledgerDetail: PropTypes.string,
  advanceDetail: PropTypes.string,
  estimatedPrice: PropTypes.number,
  onCloseModal: PropTypes.func,
  onRequestPayment: PropTypes.func,
};

export default React.memo(ConfirmPaymentModal);
