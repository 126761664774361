import { useState, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

import { ExportCSVCTA, Loader, PaginationAction } from "components";
import Filters from "./Filters";
import { ColumnTitle, StyledTableRow } from "CustomStyledComponents";
import LedgerSummary from "./LedgerSummary";

import { useFetchData, useToaster } from "hooks";
import { getCall_v2 } from "services";
import { moneyFormat } from "utils";

import { error } from "constants";

const ROWS_PER_PAGE = 20;

const getFormattedMisLedgerData = (ledgerData, type) => {
  let formattedData = ledgerData;
  if (type === "Buyer Mis") {
    formattedData = ledgerData.map((item) => {
      return {
        ...item,
        postingDate: item?.whenCreated,
        debitAmount: item?.money?.displayAmount,
        buyerType: item?.type,
        balanceAmount: item?.balanceAmount?.displayAmount,
      };
    });
  }
  return formattedData;
};

const LedgerTable = ({
  tableHeaderData,
  apiUrl,
  isDateFilter = true,
  misTableHeaderData = [],
}) => {
  const [page, setPage] = useState(0);
  const [filterApiUrl, setFilterApiUrl] = useState(null);
  const [searchParams] = useSearchParams();
  const [ledgerTypeValue, setLedgerTypeValue] = useState("Buyer");
  const [ledgerData, setLedgerData] = useState({});
  const tab = searchParams.get("tab");
  const triggerToaster = useToaster();

  /**
   * @description - invoked when user resets data
   */
  const onResetFilters = () => {
    setFilterApiUrl(apiUrl);
    setLedgerTypeValue("Buyer");
    if (page) {
      setPage(0);
    }
  };

  /**
   * @description - invoked when user submits filter
   */
  const getFilterData = useCallback(
    (fromDateTs, toDateTs, gstin, companyName, orderNumber, ledgerType) => {
      let filterEndPoint = "";
      const API_URL =
        ledgerType === "Buyer Mis"
          ? "/oms/payment/ledger?category=BUYER&isBuyerMis=true"
          : apiUrl;
      if (ledgerType) {
        setLedgerTypeValue(ledgerType);
      }

      switch (tab) {
        case "accountPayable":
        case "accountReceivable": {
          filterEndPoint = `${API_URL}&name=${companyName}`;
          break;
        }
        default: {
          const toDate = `&maxTimestamp=${toDateTs}`;
          const fromDate = `&minTimestamp=${fromDateTs}`;
          const ref = gstin ? `&referenceId=${gstin}` : "";
          const orderNo = orderNumber ? `&orderNumber=${orderNumber}` : "";
          filterEndPoint = `${API_URL}${fromDate}${toDate}${ref}${orderNo}`;
          break;
        }
      }
      filterEndPoint !== filterApiUrl && setFilterApiUrl(filterEndPoint);
      if (page) {
        setPage(0);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterApiUrl, apiUrl],
  );

  const handleError = (err) => {
    console.error(err);
  };

  // Set response on success api
  const handleSuccess = (res) => {
    setLedgerData(res);
  };

  const ledgerUrl = filterApiUrl
    ? `${filterApiUrl}&offSet=${page * ROWS_PER_PAGE}&limit=${ROWS_PER_PAGE}`
    : `${apiUrl}&offSet=${page * ROWS_PER_PAGE}&limit=${ROWS_PER_PAGE}`;

  const filterQueryParams = new URLSearchParams(filterApiUrl);
  const searchBuyerGst = filterQueryParams.get("referenceId");

  const { refetch: getLedgerData, isFetching: isLoading } = useFetchData(
    `ledger-service`,
    ledgerUrl,
    handleSuccess,
    handleError,
  );

  const { refetch: getLedgerBalance, data: ledgerBalanceData } = useFetchData(
    `ledger-balance`,
    `/oms/payment/fetchAllBuyerAmounts?reference=${searchBuyerGst}`,
  );

  useEffect(() => {
    searchBuyerGst && getLedgerBalance();
  }, [searchBuyerGst]);

  useEffect(() => {
    if (!searchParams.get("buyer")) {
      getLedgerData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterApiUrl, page]);

  /**
   * @description invoked when user needs to download ledger report
   */
  const handleDownloadFile = async () => {
    try {
      const ledgerUrl = filterApiUrl
        ? `${filterApiUrl}&offSet=0&limit=1000`
        : `${apiUrl}&offSet=0&limit=1000`;

      const { data: response } = await getCall_v2(ledgerUrl);
      const ledgerDataRes = response?.details ?? response?.result;
      return getFormattedMisLedgerData(ledgerDataRes, ledgerTypeValue);
    } catch (err) {
      triggerToaster(`Please try again`, error);
      console.error(`Error in file download - ${err}`);
    }
  };

  const totalCount =
    ledgerData?.data?.details?.length ?? ledgerData?.data?.totalCount;

  const res = ledgerData?.data?.details ?? ledgerData?.data?.result;
  const formattedLedgerData = getFormattedMisLedgerData(res, ledgerTypeValue);

  const handlePageChange = useCallback(
    (_event, pageNumber) => setPage(pageNumber),
    [],
  );

  const onResetPagination = () => {
    if (page) {
      setPage(0);
    }
  };

  const isBuyerMisLedger = ledgerTypeValue === "Buyer Mis";
  const headers = isBuyerMisLedger ? misTableHeaderData : tableHeaderData;

  return (
    <>
      <Box mb={8}>
        <Filters
          onSubmit={getFilterData}
          isDateFilter={isDateFilter}
          onReset={onResetFilters}
          resetPagination={onResetPagination}
        />
      </Box>
      {tab === "buyer" && searchBuyerGst && (
        <LedgerSummary
          balanceAmount={moneyFormat(
            ledgerBalanceData?.data?.balanceAmount ?? 0,
          )}
          netBalanceAmount={moneyFormat(
            ledgerBalanceData?.data?.netBalanceAmount ?? 0,
          )}
          blockedAmount={moneyFormat(
            ledgerBalanceData?.data?.blockedAmount ?? 0,
          )}
        />
      )}
      <Box display="flex" justifyContent="flex-end">
        <ExportCSVCTA
          onExportData={handleDownloadFile}
          tableFields={headers}
          fileName={isBuyerMisLedger ? "MIS_list" : `${tab}_list`}
        />
      </Box>
      <Box component={Paper}>
        <TableContainer
          sx={{
            minWidth: 700,
            maxHeight: "calc(100vh - 120px)",
          }}
        >
          <Table stickyHeader>
            <TableHead>
              {headers.map((el) => (
                <TableCell key={el.field}>
                  <ColumnTitle>{el.title}</ColumnTitle>
                </TableCell>
              ))}
            </TableHead>
            <TableBody>
              {isLoading || !formattedLedgerData?.length ? (
                <StyledTableRow>
                  <TableCell colSpan={headers.length} align="center">
                    {isLoading ? (
                      <Loader sx={{ marginTop: 0 }} />
                    ) : (
                      <Typography textAlign={"center"}>
                        No data found
                      </Typography>
                    )}
                  </TableCell>
                </StyledTableRow>
              ) : (
                formattedLedgerData?.map((row) => (
                  <StyledTableRow key={row?.customerOrdNumber}>
                    {headers?.map((el) => (
                      <TableCell key={el?.field}>{el.render(row)}</TableCell>
                    ))}
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!!formattedLedgerData?.length && (
          <TablePagination
            rowsPerPageOptions={[ROWS_PER_PAGE]}
            rowsPerPage={ROWS_PER_PAGE}
            page={page}
            count={totalCount ?? 0}
            onPageChange={handlePageChange}
            sx={{ display: "flex", flexDirection: "column-reverse" }}
            ActionsComponent={PaginationAction}
          />
        )}
      </Box>
    </>
  );
};

LedgerTable.propTypes = {
  tableHeaderData: PropTypes.array.isRequired,
  apiUrl: PropTypes.string.isRequired,
  isDateFilter: PropTypes.string,
};

export default LedgerTable;
