import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import PropTypes from "prop-types";

import { TextField } from "components";

const DatePicker = ({
  value,
  onChange,
  disabled,
  dateStyle,
  size = "small",
  disablePast = false,
  disableFuture = false,
  customPadding,
  minDate,
  ...rest
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        value={value || null}
        disablePast={disablePast}
        disableFuture={disableFuture}
        minDate={minDate && new Date(minDate)}
        onChange={onChange}
        disabled={disabled || false}
        inputFormat="dd/MM/yyyy"
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              ...(dateStyle && { ...dateStyle }),
            }}
            size={size}
            data-testid="date-picker"
            area-label="Choose date"
          />
        )}
        {...rest}
      />
    </LocalizationProvider>
  );
};

DatePicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  dateStyle: PropTypes.object,
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
};

export default DatePicker;
