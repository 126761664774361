import { Typography, useTheme } from "@mui/material";

import { HyperLink, Timestamp } from "components";
import LedgerTable from "../components/LedgerTable";
import InvoiceDownload from "../components/InvoiceDownload";

import { appendRupeeIcon, formatDate, validateNull } from "utils";
import { getCustomerName } from "../utils";

import { Ledger } from "../constants";

const BuyerTab = () => {
  const theme = useTheme();

  const ledgerMisHeaders = [
    {
      title: "Posting date",
      field: "postingDate",
      render: (ele) => <Timestamp timeStamp={ele.postingDate} />,
    },
    {
      title: "Document Date",
      field: "notes.invoiceDate",
      render: (ele) => (
        <>
          {ele?.notes?.description?.includes(Ledger.incomingPayment) ? (
            <Typography fontSize={14}>
              {validateNull(formatDate(ele?.notes?.originalWhenUpdated))}
            </Typography>
          ) : (
            <Typography fontSize={14}>
              {validateNull(formatDate(ele?.notes?.invoiceDate))}
            </Typography>
          )}
        </>
      ),
    },
    {
      title: "Due date",
      field: "notes.dueDate",
      render: (ele) => (
        <Typography fontSize={14}>
          {validateNull(formatDate(ele?.notes?.dueDate))}
        </Typography>
      ),
    },
    {
      title: "Document type",
      field: "notes.description",
      render: (ele) => (
        <Typography fontSize={14}>
          {validateNull(ele?.notes?.description)}
        </Typography>
      ),
    },
    {
      title: "Transaction type",
      field: "notes.transactionType",
      render: (ele) => (
        <Typography fontSize={14}>
          {validateNull(ele?.notes?.transactionType)}
        </Typography>
      ),
    },
    {
      title: "Order id",
      field: "notes.customerOrderNumber",
      render: (ele) => (
        <Typography fontSize={15}>
          <HyperLink title={ele?.notes?.customerOrderNumber} />
        </Typography>
      ),
    },
    {
      title: "Document id",
      field: "documentId",
      render: (ele) => {
        return ele?.notes?.invoiceNumber ? (
          <InvoiceDownload ele={ele} />
        ) : (
          <Typography fontSize={14}>{ele?.documentId}</Typography>
        );
      },
    },
    {
      title: "Document amount",
      field: "notes.documentAmount",
      render: (ele) => (
        <Typography fontSize={14}>
          {validateNull(ele?.notes?.documentAmount)}
        </Typography>
      ),
    },
    {
      title: "Pay later",
      field: "notes.payLater",
      render: (ele) => (
        <Typography fontSize={14}>
          {validateNull(ele?.notes?.payLater)}
        </Typography>
      ),
    },
    {
      title: "Debit",
      field: "debitAmount",
      render: (ele) => {
        const isCredit = ele?.type === Ledger.credit;
        return (
          <Typography
            fontSize={14}
            variant="body2"
            style={{ color: theme.palette.error.dark }}
          >
            {validateNull(
              !isCredit &&
                ele?.debitAmount &&
                appendRupeeIcon(`${ele?.debitAmount}`),
            )}
          </Typography>
        );
      },
    },
    {
      title: "Credit",
      field: "money.displayAmount",
      render: (ele) => {
        const isCredit = ele?.type === Ledger.credit;
        return (
          <Typography
            fontSize={14}
            variant="body2"
            style={{ color: theme.palette.success.dark }}
          >
            {validateNull(
              isCredit &&
                ele?.money?.displayAmount &&
                appendRupeeIcon(`${ele?.money?.displayAmount}`),
            )}
          </Typography>
        );
      },
    },
    // Needed for export
    {
      title: "",
      field: "buyerType",
      render: () => null,
    },
    {
      title: "Balance",
      field: "balanceAmount",
      render: (ele) => (
        <Typography fontSize={14}>
          {appendRupeeIcon(ele?.balanceAmount)}
        </Typography>
      ),
    },
  ];

  const tableHeaderData = [
    {
      title: "Posting date",
      field: "whenCreated",
      render: (ele) => <Timestamp timeStamp={ele.whenCreated} />,
    },
    {
      title: "Customer name",
      field: "notes.buyerCompanyName",
      render: (ele) => {
        const isCredit = ele?.type === Ledger.credit;
        return (
          <Typography fontSize={14}>
            {getCustomerName(ele?.notes, ele?.transactionReference, isCredit)}
          </Typography>
        );
      },
      sxCell: { maxWidth: 180 },
    },
    {
      title: "Transaction id",
      field: "transactionReference",
      render: (ele) => (
        <Typography
          maxWidth={240}
          sx={{ overflowWrap: "anywhere" }}
          fontSize={14}
        >
          {validateNull(ele.transactionReference)}
        </Typography>
      ),
      sxCell: { maxWidth: 200 },
    },

    {
      title: "Order id",
      field: "notes.customerOrderNumber",
      render: (ele) => <HyperLink title={ele?.notes?.customerOrderNumber} />,
    },
    {
      title: "Description",
      field: "notes.description",
      render: (ele) => (
        <Typography fontSize={14}>
          {validateNull(ele?.notes?.description)}
        </Typography>
      ),
      sxCell: { maxWidth: 180 },
    },
    // Needed for export
    {
      title: "",
      field: "type",
      render: () => null,
    },
    {
      title: "Amount",
      field: "amount",
      render: (rowData) => {
        const isCredit = rowData?.type === Ledger.credit;
        return (
          <Typography
            style={{
              color: isCredit
                ? theme.palette.success.dark
                : theme.palette.error.dark,
            }}
            fontSize={14}
          >
            {appendRupeeIcon(`${rowData?.money?.displayAmount ?? "0"}`)}
            {`(${isCredit ? "Cr." : "Dr."})`}
          </Typography>
        );
      },
    },
    {
      title: "Balance",
      field: "balance",
      render: (ele) => (
        <Typography fontSize={14}>
          {appendRupeeIcon(validateNull(ele?.balanceAmount?.displayAmount))}
        </Typography>
      ),
    },
  ];

  return (
    <LedgerTable
      tableHeaderData={tableHeaderData}
      apiUrl={"/oms/payment/ledger?category=BUYER"}
      misTableHeaderData={ledgerMisHeaders}
    />
  );
};

export default BuyerTab;
