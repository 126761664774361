import { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TableBody,
  useTheme,
  Stack,
} from "@mui/material";
import PropTypes from "prop-types";

import { ButtonV1, HyperLink, Loader, ModalBox } from "components";

import { moneyFormat, validateNull } from "utils";
import { selectedCustomerPropTypes } from "./propTypes";

import { useFetchData } from "hooks";
import CUSTOMER_LEDGER from "./constants";

const CreditTable = ({ selectedCustomer }) => {
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [blockedAmountData, setBlockedAmountData] = useState({});

  const theme = useTheme();

  const pan = selectedCustomer?.gstin.substr(2, 10);
  const {
    refetch: fetchCreditLimitData,
    data: creditLimitData,
    isFetching,
  } = useFetchData(
    "credit-data",
    `/joms/api/credit/internal/v1/getCreditInfoByPan?pan=${pan}`,
  );

  useEffect(() => {
    fetchCreditLimitData();
  }, [selectedCustomer]);

  const toggleModal = () => {
    setIsModelOpen(!isModelOpen);
  };

  const handleViewBlockedAmount = (blockedAmountData) => {
    setBlockedAmountData(blockedAmountData);
    toggleModal();
  };

  const getFormattedBlockedAmount = () => {
    const formattedBlockedAmount = Object.keys(
      blockedAmountData?.blockedDetails || {},
    )?.map((item) => {
      return {
        orderId: item,
        blockedAmount: blockedAmountData?.blockedDetails[item],
      };
    });

    return formattedBlockedAmount || [];
  };

  const getStatus = (isCreditBlocked) => {
    if (isCreditBlocked) {
      return {
        status: "Frozen",
        color: theme.palette.error.dark,
      };
    } else {
      return {
        status: "Unfrozen",
        color: theme.palette.success.dark,
      };
    }
  };

  return (
    <Box mt={4}>
      <Typography fontSize={18} fontWeight={"600"}>
        Approved credit limits
      </Typography>
      <Paper>
        <TableContainer
          sx={{
            minWidth: 700,
            maxHeight: "calc(100vh - 120px)",
            marginTop: 2,
          }}
        >
          <Table stickyHeader>
            <TableHead style={{ borderRadius: "10px 10px 0 0" }}>
              <TableRow>
                {CUSTOMER_LEDGER.creditHeader.map((heading) => (
                  <TableCell key={heading}>{heading}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching ? (
                <TableRow>
                  <TableCell colSpan={CUSTOMER_LEDGER.creditHeader.length}>
                    <Loader sx={{ margin: 0 }} />
                  </TableCell>
                </TableRow>
              ) : !!creditLimitData?.data?.length ? (
                creditLimitData?.data?.map((limit, index) => {
                  return (
                    <>
                      <TableRow key={index}>
                        <TableCell>
                          <Typography variant="body2">
                            {validateNull(limit.creditProgramName)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {validateNull(limit.creditDueDay)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {moneyFormat(limit.creditSanctioned)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {moneyFormat(limit.creditUtilised)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {moneyFormat(limit.creditAvailable)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {moneyFormat(limit.creditBlocked)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color={getStatus(limit?.isCreditBlocked)?.color}
                            variant="body2"
                          >
                            {validateNull(
                              getStatus(limit?.isCreditBlocked)?.status,
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <ButtonV1
                            variant="outlined"
                            color="primary"
                            size="small"
                            title={"View blocked amount"}
                            onClick={() => handleViewBlockedAmount(limit)}
                            style={{ fontSize: 14 }}
                          />
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={CUSTOMER_LEDGER.creditHeader.length}>
                    <Typography textAlign={"center"}>No data found</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <ModalBox open={isModelOpen} onCloseModal={toggleModal}>
        <Box mt={4} mb={4} style={{ display: "flex", flexDirection: "row" }}>
          <Typography fontWeight="bold" fontSize={18}>
            {blockedAmountData?.customerName}
          </Typography>
          <Typography
            style={{
              color: theme.palette.grey["1000"],
              fontSize: 18,
              marginLeft: 8,
            }}
          >
            {blockedAmountData?.customerGstins?.[0]}
          </Typography>
        </Box>
        <Stack direction={"row"}>
          <Typography fontWeight={"bold"}>Blocked amount: </Typography>
          <Typography ml={2}>
            {moneyFormat(blockedAmountData?.creditBlocked)}
          </Typography>
        </Stack>
        <Paper>
          <TableContainer
            sx={{
              minWidth: 700,
              maxHeight: "calc(100vh - 120px)",
              marginTop: 2,
            }}
          >
            <Table stickyHeader>
              <TableHead style={{ borderRadius: "10px 10px 0 0" }}>
                <TableRow>
                  <TableCell>{"Order id"}</TableCell>
                  <TableCell>{"Blocked amount"}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!!getFormattedBlockedAmount()?.length ? (
                  getFormattedBlockedAmount()?.map((item, index) => {
                    return (
                      <>
                        <TableRow key={index}>
                          <TableCell>
                            <HyperLink title={item.orderId} />
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">
                              {moneyFormat(item.blockedAmount)}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography textAlign={"center"}>
                        No data found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </ModalBox>
    </Box>
  );
};

export default CreditTable;

CreditTable.propTypes = {
  selectedCustomer: PropTypes.shape(selectedCustomerPropTypes).isRequired,
};
